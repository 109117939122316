import { FC } from 'react'
import { Organisms } from '@/components'

export const HomePage: FC = () => {
  return (
    <div className="flex overflow-hidden flex-col min-h-screen">
      <Organisms.Header />
      <main>
        <div className="mt-20">
          <Organisms.HeroHome />
        </div>
      </main>
    </div>
  )
}
